import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import BaseLayout from "../../components/layout"
import Seo from "../../components/seo";
import HorizontalRule from "../../components/horizontal-rule";
import BreadcrumbTrail from "../../components/breadcrumb-trail";

export default function TermsOfServicePage(props) {
  const { site } = useStaticQuery(query)

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Terms of Service',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Terms of Service" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="mb-16 md:mb-28">
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Terms & Conditions</h1>
          </div>
        </div>

        <div className="layout-reading-w app-px-base article-layout article-layout--standardFont">
          <h4>Terms of Service</h4>
          <p>Welcome to the { site.siteMetadata.company } website (“the Site,” “we,” “us,” "{ site.siteMetadata.company },” “website,” or “our”). { site.siteMetadata.company } provides this site as a service to its customers. Please read the following terms of service (“Terms”) as they govern your use of our site. By using this Site, you agree to follow and be bound by the following rule below. If you do not agree to these Terms, please do not use this Site in any way. Additionally, { site.siteMetadata.company } reserves the right to modify these Terms at any time without prior notification. Site users must agree upon and abide by these changes accordingly. Please review this page periodically for changes. Any use of our Site at any time constitutes full acceptance of our service Terms.</p>

          <h4 className="mt-6">Description of Service</h4>
          <p>{ site.siteMetadata.company } is a tech company offering our users general content, among other things. Unless explicitly stated otherwise, any new features that augment or enhance the current { site.siteMetadata.company } service shall be subject to these Terms of Service.</p>

          <h4 className="mt-6">Usage Rescrictions</h4>
          <p>All of the content that appears on the { site.siteMetadata.company } website, including all ideas, visuals, text, audio and video clips are subject to copyright protections and/or other intellectual property rights or licenses held by { site.siteMetadata.company }. The entire content of the { site.siteMetadata.company } website is copyrighted as a collective work under U.S. copyright laws. { site.siteMetadata.company } grants you a limited license to access and make personal use of the content on this website. Content of the { site.siteMetadata.company } website is intended solely for the personal, noncommercial use by the users of our Site.</p>

          <h6>Account Profile Eligibility</h6>
          <p>Our membership services are not available to users under the age of 18 or to members who, at our discretion, have been suspended or removed from the { site.siteMetadata.company } systems. Members agree to all terms of service and must only apply for membership if eligible.</p>

          <h4 className="mt-6">Notice</h4>
          <p>{ site.siteMetadata.company } may contact you or provide you with service-related and/or promotional notices by means of postal mail, electronic mail, general site notifications and more using the contact information you have provided to us. After confirmation of an appointment, sale, or opt-in newsletter signup, the customer is added to { site.siteMetadata.company } mailing list. The customer can unsubscribe from this list at any time.</p>

          <h4 className="mt-6">Images</h4>
          <p>By engaging in business with { site.siteMetadata.company }, you are hereby allowing { site.siteMetadata.company } to take your picture for use at our discretion. These images may be sent to { site.siteMetadata.company } business partners and vendors for business purposes only.</p>

          <h4 className="mt-6">Site Use</h4>
          <p>You cannot access or use the { site.siteMetadata.company } website for any illegal or unauthorized purpose. Harassment in any manner or form of the Site, including via e-mail and chat or by obscene or abusive language, is strictly forbidden. Although { site.siteMetadata.company } cannot monitor the conduct of its users, it is a strict violation of these Terms to use any information obtained from our Site in order to harass, abuse or harm another person, or in order to contact, advertise, solicit or sell to any user without his/her prior explicit consent. Also, you agree that no comments or other user submissions submitted by you to the { site.siteMetadata.company } website will violate any right of any third party, including copyright, trademark, privacy or other personal rights. You further agree that no comments submitted by you to the Site will be or contain libelous or otherwise unlawful, abusive or obscene material. You are and shall remain solely responsible for the content of any comments you make.</p>

          <h4 className="mt-6">User Submissions</h4>
          <p>{ site.siteMetadata.company } strives to create an interactive relationship with the members of its Site and services. User comments, feedback, suggestions, ideas, and other submissions disclosed, submitted or offered to { site.siteMetadata.company } on or by this Site or via any other method of communication, including but not limited to phone, email, or chat, shall be and remain { site.siteMetadata.company } property. Users assign all property rights and interests to { site.siteMetadata.company } allowing our company unlimited use, commercial or otherwise, of any comments. Furthermore, { site.siteMetadata.company } shall be under no obligation (1) to maintain any comments in confidence; (2) to pay to users any compensation for any comments; or (3) to respond to any user comments.</p>

          <h4 className="mt-6">Correction of Site Errors</h4>
          <p>The information on the { site.siteMetadata.company } website may contain typographical errors or inaccuracies. We reserve the right to update any information we see fit at any time without prior notice. Please note that such errors may relate to product information, pricing and availability. In these instances, we shall have the right to terminate any orders involving pricing errors or inaccuracies. We apologize for any inconveniences.</p>

          <h4 className="mt-6">Modification/Termination of Website</h4>
          <p>In the event of termination, you will still be bound by your obligations under these terms of service, including the warranties made by you, and by the disclaimers and limitations of liability. { site.siteMetadata.company } shall not be liable to you or any third-party for any termination of your access.</p>

          <h4 className="mt-6">Third Party Links</h4>
          <p>To improve our Site, { site.siteMetadata.company } may use and promote services provided by outside third parties. However, even if the third party is affiliated with { site.siteMetadata.company }, we do not control these services and make no representations regarding these persons or entities. We are not liable or responsible for the accuracy, completeness, timeliness, reliability or availability of, any such parties. Outside sites linked on our Site, all of which have separate privacy and data collection practices, are only for your convenience and therefore you access them at your own risk.</p>
          <p>WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS A RESULT OF ANY DEALINGS WITH ANY THIRD PARTY WEBSITE OR MERCHANT OR OPERATOR OF SUCH A THIRD PARTY WEBSITE.</p>

          <h4 className="mt-6">Indemnity</h4>
          <p>You agree to indemnify and hold { site.siteMetadata.company } and its subsidiaries, affiliates, officers, agents, employees, partners and licensors harmless from any claim or demand, including but not limited to reasonable attorneys' fees, made by any third party due to or arising out of Content you submit, post, transmit or otherwise make available through the Service, your use of the OSI Service, your connection to the Service, your violation of the Terms of Service, or your violation of any rights of another.</p>

          <h4 className="mt-6">Disclaimer of Warrenty</h4>
          <p>ALL CONTENT, PRODUCTS, AND SERVICES ON THE SITE, OR OBTAINED FROM A WEBSITE TO WHICH THE SITE IS LINKED (A "LINKED SITE") ARE PROVIDED TO YOU "AS IS" WITHOUT ANY GUARANTEES OR WARRANTY. IN CONNECTION WITH ALL CONTENT, PRODUCTS AND SERVICES ON THIS SITE, { site.siteMetadata.company } MAKES NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY OR ACCURACY. USE OF THE PRODUCT BY A USER IS AT THE USER’S RISK.</p>

          <h4 className="mt-6">Limitation of Liability</h4>
          <p>{ site.siteMetadata.company } SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS AND SERVICES ON THIS SITE OR THE PERFORMANCE OF ANY ITS PRODUCTS (EVEN IF { site.siteMetadata.company } HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES).</p>
        </div>
      </div>
    </BaseLayout>
  )
}

const query = graphql`
  query TOS {
    site {
      siteMetadata {
        company
        siteUrl
        emailAddress
      }
    }
  }
`
